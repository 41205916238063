@use 'src/styles/utilities/_variables' as vr;

.title {
  text-align: center;
  font-size: 22px;
  color: var(--colorTextGray);
  margin: vr.$spacingSize80 0 vr.$spacingSize60;
}

.summary {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: center;
  padding: 0 vr.$spacingSize60 vr.$spacingSize50;
  max-height: 450px;
  overflow: hidden;
}

.doughnutWrapper {
  max-width: 500px;

  &.small {
    max-width: 200px;
  }
}

.list {
  overflow: auto;
  max-height: 400px;
  padding-right: vr.$spacingSize40;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--colorPrimary);
  }
}

.element {
  display: flex;
  align-items: center;
  gap: 4px;
}

.elementColor {
  width: 20px;
  height: 20px;
  margin-right: vr.$spacingSize20;
}

.elementName {
  white-space: nowrap;
}
.elementAmount {
  font-weight: bold;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.noResultsTitle {
  font-size: 22px;
  color: var(--colorTextGray);
  margin: vr.$spacingSize100 0;
  text-align: center;
}
