
.checkboxWrapper {
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  cursor: pointer;
}

.checkboxRect {
  margin-top: 2px;
  border-radius: 2px;
}
.checkboxLabel {
  font-size: 14px;
  line-height: 21px;
  font-weight: var(--fontPrimaryWeightRegular);
}

.disabled {
  color: var(--colorGray30);
}

.large {
  font-size: 16px;
  line-height: 24px;
}
