@use "src/styles/utilities/_variables" as vr;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 40px);
}

.error {
  color: var(--colorTextError);
  text-align: center;
  margin-top: vr.$spacingSize50;
  font-size: 18px;
}

.input {
  max-width: 100%;
  color: var(--colorBackgroundPrimary);
  font-size: 0;
  padding: 5px;
  background-color: var(--backgroundPrimaryDark);
  border-radius: 10px;
  border: none;

  &::file-selector-button {
    margin-right: 20px;
    border: none;
    background: var(--colorPrimary);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--colorWhite);
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }

  &::file-selector-button:hover {
    background: var(--colorPrimaryDark);
  }
}

.dropContainer {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  width: 100%;
  max-width: 80%;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.dropContainer:hover {
  background: #dedcdc;
  border-color: #111;
}

.dropContainer:hover .dropTitle {
  color: #222;
}

.dragActive {
  background: #dedcdc;
  border-color: #111;

  .dropTitle {
    color: #222;
  }
}

.dropTitle {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}
