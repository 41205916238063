@use "src/styles/utilities/_variables" as vr;

.filterButton {
  position: fixed;
  left: vr.$spacingSize70;
  bottom: vr.$spacingSize70;
  background: var(--colorPrimary);
  border-radius: 50%;
  box-shadow: var(--boxShadow);
  margin-left: 0;
  margin-bottom: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--colorPrimaryHover);
  }
}

.filterButtonIcon {
  width: 28px;
  height: 28px;
  color: var(--colorWhite);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  min-width: 300px;
  gap: vr.$spacingSize60;
  padding: 0 vr.$spacingSize70;
  max-height: calc(90vh - 40px);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--colorPrimary);
  }
}

.contentTitle {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: vr.$spacingSize30;
  margin-bottom: vr.$spacingSize30;
}

.namesWrapper {
  display: flex;
  flex-direction: column;
  gap: vr.$spacingSize10;
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--colorPrimary);
  }
}