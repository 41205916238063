@use "src/styles/utilities/_variables" as vr;

.wrapper {
  display: flex;
  flex-direction: column;
}

.section {
  margin: vr.$spacingSize80 0 0;
  padding-bottom: vr.$spacingSize80;
  page-break-after: always;

  &:nth-child(odd) {
    background-color: var(--colorSectionPrimary);
  }

  &:nth-child(even) {
    background-color: var(--colorSectionSecondary);
  }
}

.sectionTitle {
  font-size: 32px;
  text-align: center;
  margin-bottom: vr.$spacingSize30;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: vr.$spacingSize50;

  &.printView {
    display: none;
  }
}


.actionButton {
  font-weight: bold;
  display: flex;
  font-family: var(--fontFamilyPrimary);
  color: var(--colorTextGray);
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: auto;

  .icon {
    margin-left: vr.$spacingSize10;
  }
}

.subtitle {
  text-align: center;
  color: var(--colorTextGray);
  font-size: 18px;
  font-weight: 500;
}
